<template>
  <div class="main">
    <Home />
    <About />
    <Works />
  </div>
</template>

<script>
import About from '@/components/About.vue';
import Home from '@/components/Home.vue';
import Works from '@/components/Works.vue';

export default {
  name: 'Main',
  components: { Home, Works, About },
  activated() {
    window.scrollTo({
      top: this.$store.state.scrollPosition,
      behavior: 'instant',
    });
  },
};
</script>
